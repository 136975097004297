<!-- eslint-disable vue/html-self-closing -->
<template>
  <!-- BEGIN: Content-->
  <div
    class="app-content content ms-0"
  >
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>

    <ConvoInformation
      v-if="!loading"
      @showModal="openModal"
    />

    <SendConvoModal
      :show="showModal"
    />
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import SendConvoModal from './partials/modals/SendConvoModal.vue'
import ConvoInformation from './ConvoInformation.vue'

export default {
  components: {
    SendConvoModal,
    ConvoInformation,
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  mounted() {
    this.loading = true
    this.fetchConvo()
  },
  methods: {
    async fetchConvo() {
      if (this.$route.name === 'front.senior-call') {
        await this.$store.dispatch('convo/fetch')
      } else if (this.$route.name === 'front.academy-call') {
        await this.$store.dispatch('convo/academyFetch')
      }

      document.title = `ICREA - ${this.convo.title}`

      await this.$store.dispatch('convo/fetchSections', this.convo.id)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.loading = false
    },
    goToPreview() {
      this.$router.push({ name: 'front.senior-call-preview' })
    },
    openModal(val) {
      this.showModal = !this.showModal
    },
  },
}
</script>
